<template>
  <span
    v-if="user"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user dropdown-text"
  >
    <span>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ fullNameUser  }} 
        </p>        
      </div>            
    </span>
    <b-dropdown-text :class="windowWidth > 575 ? 'd-none' : '' ">
          <p class="user-name font-weight-bolder mb-0">
            {{ user.name }} {{ user.lastname }} 
          </p>
          
          <!-- badge="" -->
          <!-- badge-variant="success" -->
    </b-dropdown-text>
    
  </span>
</template>

<script>

import { mapMutations, mapState } from 'vuex'

export default {
  
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require('@/assets/images/default.jpg'),
      userData: JSON.parse(localStorage.getItem('userData')),
      avatar: require('@/assets/images/avatars/13-small.png'),                  
    }
  },
  computed:{
    ...mapState('shop',['puntoventa']),                     
    ...mapState('auth',['user']),
    ...mapState('appConfig',['windowWidth']),    
    fullNameUser(){
      const stringName = this.user.name +' '+ this.user.lastname
      return this.windowWidth > 1300 ? stringName : stringName.substring(0, 12) + '...'        
    },
    puntoDeVenta(){
      if( this.puntoventa && this.user ){
        const puntoDeVenta = this.user.puntosventa.find( pv => pv.id === this.puntoventa )
        return puntoDeVenta.name 
      } else {
        return ''
      }              
    }
  },
  methods: {
    ...mapMutations('auth',['logout',]),
    ...mapMutations('shop',['unsetCanalVenta','unsetPuntoDeVenta','setEmptyCart','unsetLastNameSearch','unsetDataRoom']),
    ...mapMutations('bookings',['resetToInitialState']),
    ...mapMutations('products',['unsetProducts']),
    ...mapMutations('start',['setSelectedCategory']),
    _logout() {
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.resetToInitialState()
      this.unsetProducts()
      this.setSelectedCategory(null)      
      this.$router.push({ name: 'auth-login' })
    },
    clearAll(){
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.resetToInitialState()
      this.unsetProducts()
      this.setSelectedCategory(null)      
      // this.$router.push({ name: 'auth-login' })
      localStorage.removeItem('vuex')
      // window.location.reload()
      window.location = window.location.href+'?eraseCache=true'
    }
  },
}
</script>
