<template>
  <div class="clearfix mb-0">
    <div class="float-md-left d-block d-md-inline-block mt-25" v-if="token === '8c01b5663e38a278ecf2d63403dc02e596f4c54d' ">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.theepicvacations.com/"
        target="_blank"
        :class="$store.state.appConfig.layout.skin === 'light' ? 'text-light-color' : 'text-dark-color'"        
      ><b>{{brand}}</b></b-link>  
      <div class="d-none d-sm-inline-block ml-25"> All rights reserved</div> | 
      <b-link
        class="ml-25"
        href="tel:18008404070"        
        :class="$store.state.appConfig.layout.skin === 'light' ? 'text-light-color' : 'text-dark-color'"        
      ><b> <feather-icon icon="PhoneCallIcon" size="18" /> Contact us!:   USA/CAN 1 800 840-4070 </b></b-link> |
      
       <b-link
        class="ml-25"
        href="tel:+529842420313"        
        :class="$store.state.appConfig.layout.skin === 'light' ? 'text-light-color' : 'text-dark-color'"        
      ><b> REST OF THE WORLD +52 (984) 242-0313 </b></b-link> |
      <b-link
        class="ml-25"
        href="mailto:info@theepicvacations.com"        
        :class="$store.state.appConfig.layout.skin === 'light' ? 'text-light-color' : 'text-dark-color'"        
      ><b> <feather-icon icon="MailIcon" size="18" /> info@theepicvacations.com</b></b-link>	      
    </div>

    <div class="float-md-left d-block d-md-inline-block mt-25" v-else>
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.thefiveshotels.com/"
        target="_blank"
        :class="$store.state.appConfig.layout.skin === 'light' ? 'text-light-color' : 'text-dark-color'"        
      >The Fives Hotels & Residences</b-link>
      
      <div class="d-none d-sm-inline-block">, All rights reserved</div>
    </div>

    <div class="float-md-right d-none d-md-block"> Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current mr-1"
      />
      <!-- y a mano
      <b-icon icon="hand-index-thumb" aria-hidden="true"></b-icon> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('auth',['queryParams','user']),
    token(){
      return (this.queryParams && this.queryParams.token ) ? this.queryParams.token : ''      
    },
    brand(){
      return  this.user ? `${this.user.name} ${this.user.lastname}` : ''      
    }
  }
}
</script>
<style scoped>
.text-light-color{
  color: black;
}

.text-dark-color{
  color: white;
}
</style>